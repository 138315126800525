import { Action } from "./actions";

const initialState = {
  isWaiting: false,
  authenticated: false,
  jwt: "",
  user: {},
  email: "",
  categories: [],
  currentBudget: {},
  budgets: [],
  notification: {},
  expenses: [],
  oneTimeExpenses: [],
  recurringExpenses: [],
  theme: "light",
};

function reducer(state = initialState, action) {
  switch (action.type) {
    /**** User Login  ****/
    case Action.FinishLoggingInUser:
      return {
        ...state,
        jwt: action.payload,
        authenticated: true,
      };
    case Action.FinishSettingUser:
      return {
        ...state,
        user: action.payload,
        authenticated: true,
        theme: action.payload.dark_theme === 0 ? "light" : "dark",
      };
    case Action.FinishEditingUser:
      return {
        ...state,
        user: action.payload,
      };
    case Action.FinishChangingTheme:
      let newUser = state.user;
      newUser.dark_theme = newUser.dark_theme === 0 ? 1 : 0;
      return {
        ...state,
        user: newUser,
        theme: newUser.dark_theme === 0 ? "light" : "dark",
      };
    /**** User Logout  ****/
    case Action.FinishLoggingOutUser:
      return {
        ...state,
        authenticated: false,
        jwt: "",
        user: {},
        categories: [],
        email: action.payload,
        currentBudget: {},
        budgets: [],
        expenses: [],
        oneTimeExpenses: [],
        recurringExpenses: [],
      };
    /**** User Signup  ****/
    case Action.FinishAddingUser:
      return {
        ...state,
        email: action.payload,
      };
    /**** Categories  ****/
    case Action.FinishLoadingCategories:
      return {
        ...state,
        categories: action.payload,
      };
    case Action.FinishAddingCategory:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };
    case Action.FinishEditingCategory:
      return {
        ...state,
        categories: state.categories.map((category) => {
          if (category.category_id === action.payload.category_id) {
            return action.payload;
          } else {
            return category;
          }
        }),
      };
    case Action.FinishDeletingCategory:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.category_id !== action.payload.category_id
        ),
      };
    /**** Budget ****/
    case Action.FinishSettingBudget:
      return {
        ...state,
        currentBudget: action.payload.currentBudget,
        budgets: action.payload.budgets,
      };
    case Action.SetCurrentBudget:
      return {
        ...state,
        currentBudget: action.payload,
      };
    /**** Adding budget  ****/
    case Action.FinishAddingBudget:
      return {
        ...state,
        currentBudget: action.payload,
        budgets: [action.payload, ...state.budgets],
      };
    case Action.FinishEditingBudget:
      return {
        ...state,
        budgets: state.budgets.map((budget) => {
          if (budget.budget_id === action.payload.budget_id) {
            return action.payload;
          } else {
            return budget;
          }
        }),
      };
    case Action.FinishDeletingBudget:
      return {
        ...state,
        budgets: state.budgets.filter(
          (budget) => budget.budget_id !== action.payload.budget_id
        ),
      };
    case Action.addNotification:
      return {
        ...state,
        notification: action.payload,
      };
    case Action.dismissNotification:
      return {
        ...state,
        notification: {},
      };
    /**** One Time Expenses  ****/
    case Action.FinishLoadingOneTimeExpenses:
      return {
        ...state,
        oneTimeExpenses: action.payload,
        expenses: state.expenses.concat(action.payload),
      };
    case Action.FinishAddingOneTime:
      return {
        ...state,
        oneTimeExpenses: [
          action.payload.oneTimeExpense,
          ...state.oneTimeExpenses,
        ],
        expenses: [action.payload.expense, ...state.expenses],
      };
    case Action.FinishEditingOneTime:
      return {
        ...state,
        oneTimeExpenses: state.oneTimeExpenses.map((oneTime) => {
          if (oneTime.onetime_id === action.payload.onetime_id) {
            return action.payload;
          } else {
            return oneTime;
          }
        }),
      };
    case Action.FinishDeletingOneTime:
      return {
        ...state,
        expenses: state.expenses.filter(
          (expense) => expense.expense_id !== action.payload.expense_id
        ),
        oneTimeExpenses: state.oneTimeExpenses.filter(
          (oneTimeExpense) =>
            oneTimeExpense.onetime_id !== action.payload.onetime_id
        ),
      };
    /**** Recurring Expenses  ****/
    case Action.FinishLoadingRecurringExpenses:
      return {
        ...state,
        recurringExpenses: action.payload,
        expenses: state.expenses.concat(action.payload),
      };
    case Action.FinishAddingRecurring:
      return {
        ...state,
        recurringExpenses: [
          action.payload.recurringExpense,
          ...state.recurringExpenses,
        ],
        expenses: [action.payload.expense, ...state.expenses],
      };
    case Action.FinishEditingRecurring:
      return {
        ...state,
        recurringExpenses: state.recurringExpenses.map((recurring) => {
          if (recurring.recurring_id === action.payload.recurring_id) {
            return action.payload;
          } else {
            return recurring;
          }
        }),
      };
    case Action.FinishDeletingRecurring:
      return {
        ...state,
        expenses: state.expenses.filter(
          (expense) => expense.expense_id !== action.payload.expense_id
        ),
        recurringExpenses: state.recurringExpenses.filter(
          (recurringExpense) =>
            recurringExpense.recurring_id !== action.payload.recurring_id
        ),
      };
    default:
      return state;
  }
}

export default reducer;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-regular-svg-icons";

function ExpenseRow(props) {
  const handleDelete = (e) => {
    e.preventDefault();
    props.handleDelete(
      props.expense.expense_id,
      props.expense.recurring_id
        ? props.expense.recurring_id
        : props.expense.onetime_id
    );
  };

  const handleEdit = (e) => {
    e.preventDefault();
    props.handleEdit(props.expense);
  };
  return (
    <tr>
      <td>{props.expense.name}</td>
      <td>
        {props.expense.date
          ? props.expense.date.substring(0, 10)
          : props.expense.renewal_date.substring(0, 10)}
      </td>
      <td>{props.expense.category_name}</td>
      <td>{props.expense.cost}</td>
      <td>
        <FontAwesomeIcon icon={faEdit} className="icon" onClick={handleEdit} />
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="icon"
          onClick={handleDelete}
        />
      </td>
    </tr>
  );
}

export default ExpenseRow;

import "./styles/App.css";
import "./styles/nav.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Container from "react-bootstrap/Container";
import React from "react";
import { useSelector } from "react-redux";

import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import Budget from "./pages/Budget";
import Expenses from "./pages/Expenses";
import AddBudget from "./pages/AddBudget";

const history = createBrowserHistory();

function App() {
  const theme = useSelector((state) => state.theme);

  return (
    <Router history={history}>
      <div className={`App ${theme === "dark" ? "dark" : ""}`}>
        <Switch>
          <Route exact path="/">
            <Container>
              <Home />
            </Container>
          </Route>
          <Route path="/signup">
            <Container>
              <Signup />
            </Container>
          </Route>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/budget">
            <Budget />
          </Route>
          <Route exact path="/budget/create">
            <AddBudget />
          </Route>
          <Route exact path="/account">
            <Account />
          </Route>
          {/*
          <Route exact path="/budget">
            <Budget />
          </Route>*/}
          <Route exact path="/expenses">
            <Expenses />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect } from "react";
import SideBar from "../components/SideBar";
import DashboardComponent from "../components/DashboardComponent";

import { useSelector } from "react-redux";

import { withRouter } from "react-router-dom";

function Dashboard({ history }) {
  const authenticated = useSelector((state) => state.authenticated);

  useEffect(() => {
    if (!authenticated) {
      history.push("/");
    }
  });

  return (
    <>
      <SideBar history={history} active="dashboard" />
      <DashboardComponent />
    </>
  );
}

export default withRouter(Dashboard);

import React, { useEffect } from "react";
import SideBar from "../components/SideBar";
import ExpensesComponent from "../components/ExpensesComponent";

import { useSelector } from "react-redux";

import { withRouter } from "react-router-dom";

function Expenses({ history }) {
  const authenticated = useSelector((state) => state.authenticated);

  useEffect(() => {
    if (!authenticated) {
      history.push("/");
    }
  });

  return (
    <>
      <SideBar history={history} active="expenses" />
      <ExpensesComponent history={history} />
    </>
  );
}

export default withRouter(Expenses);

import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentBudget,
  getCategories,
  startGettingOneTimeExpenses,
  startGettingRecurringExpenses,
  startAddingOneTime,
  startEditingOneTime,
  startEditingRecurring,
  AddNotification,
  startAddingRecurring,
  startDeletingRecurringExpense,
  startDeletingOneTimeExpense,
} from "../actions";
import { Button } from "react-bootstrap";

import OneTimeExpenseModal from "./OneTimeExpenseModal";
import RecurringExpenseModal from "./RecurringExpenseModal";
import Notification from "./Notification";
import ExpenseRow from "./ExpenseRow";
import DeleteModal from "./DeleteModal";

function ExpensesComponent(props) {
  const budgets = useSelector((state) => state.budgets);
  const currentBudget = useSelector((state) => state.currentBudget);
  const categories = useSelector((state) => state.categories);
  const oneTimeExpenses = useSelector((state) => state.oneTimeExpenses);
  const recurringExpenses = useSelector((state) => state.recurringExpenses);
  const jwt = useSelector((state) => state.jwt);
  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const [selectedOneTime, setSelectedOneTime] = useState({
    budget_id: -1,
    category_id: -1,
    category_name: "",
    cost: 0,
    onetime_id: 0,
    date: "",
  });

  const [selectedRecurring, setSelectedRecurring] = useState({
    budget_id: -1,
    category_id: -1,
    category_name: "",
    cost: 0,
    recurring_id: 0,
    renewal_date: "",
  });

  const [addOneTimeOpen, setAddOneTimeOpen] = useState(false);
  const [addRecurringOpen, setAddRecurringOpen] = useState(false);
  const [deleteOneTimeOpen, setDeleteOneTimeOpen] = useState(false);
  const [deleteRecurringOpen, setDeleteRecurringOpen] = useState(false);
  const [expenseToDelete, setExpenseToDelete] = useState({});
  const [editOneTimeOpen, setEditOneTimeOpen] = useState(false);
  const [editRecurringOpen, setEditRecurringOpen] = useState(false);

  useEffect(() => {
    dispatch(getCategories(user.user_id, currentBudget.budget_id, jwt));
    dispatch(
      startGettingOneTimeExpenses(user.user_id, currentBudget.budget_id, jwt)
    );
    dispatch(
      startGettingRecurringExpenses(user.user_id, currentBudget.budget_id, jwt)
    );
  }, [dispatch, user.user_id, jwt, currentBudget.budget_id]);

  const handleAddOneTimeOpen = (event) => {
    event.preventDefault();
    if (categories.length === 0) {
      dispatch(
        AddNotification({
          type: "danger",
          message: "Please add categories to your budget",
        })
      );
    } else {
      setAddOneTimeOpen(true);
    }
  };

  const handleAddOneTimeClose = () => {
    setAddOneTimeOpen(false);
  };

  const handleAddOneTime = (
    expense_id,
    onetime_id,
    name,
    cost,
    date,
    user,
    category,
    cat,
    currentBudget
  ) => {
    dispatch(
      startAddingOneTime(
        name,
        cost,
        date,
        user.user_id,
        parseInt(category),
        cat.name,
        currentBudget.budget_id,
        jwt
      )
    );
    setAddOneTimeOpen(false);
  };

  const handleAddRecurringOpen = (event) => {
    event.preventDefault();
    if (categories.length === 0) {
      dispatch(
        AddNotification({
          type: "danger",
          message: "Please add categories to your budget",
        })
      );
    } else {
      setAddRecurringOpen(true);
    }
  };

  const handleAddRecurringClose = () => {
    setAddRecurringOpen(false);
  };

  const handleDeleteOneTimeClick = (expense_id, onetime_id) => {
    setExpenseToDelete({ expense_id, onetime_id });
    setDeleteOneTimeOpen(true);
  };

  const handleDeleteRecurringClick = (expense_id, recurring_id) => {
    setExpenseToDelete({ expense_id, recurring_id });
    setDeleteRecurringOpen(true);
  };

  const handleConfirmDeleteOneTime = (expense) => {
    setDeleteOneTimeOpen(false);
    setExpenseToDelete({});
    dispatch(
      startDeletingOneTimeExpense(expense.expense_id, expense.onetime_id, jwt)
    );
  };

  const handleConfirmDeleteRecurring = (expense) => {
    setDeleteRecurringOpen(false);
    setExpenseToDelete({});
    dispatch(
      startDeletingRecurringExpense(
        expense.expense_id,
        expense.recurring_id,
        jwt
      )
    );
  };

  const handleAddRecurring = (
    expense_id,
    recurring_id,
    name,
    cost,
    renewal_date,
    user,
    category,
    cat,
    currentBudget
  ) => {
    dispatch(
      startAddingRecurring(
        name,
        cost,
        renewal_date,
        user.user_id,
        parseInt(category),
        cat.name,
        currentBudget.budget_id,
        jwt
      )
    );
    setAddRecurringOpen(false);
  };

  const handleEditOneTimeClick = (oneTime) => {
    setSelectedOneTime(oneTime);
    setEditOneTimeOpen(true);
  };

  const handleEditOneTimeClose = () => {
    setEditOneTimeOpen(false);
    setSelectedOneTime({
      budget_id: -1,
      category_id: -1,
      category_name: "",
      cost: 0,
      onetime_id: 0,
      date: "",
    });
  };

  const handleEditOneTime = (
    expense_id,
    onetime_id,
    name,
    cost,
    date,
    user,
    category,
    cat,
    currentBudget
  ) => {
    dispatch(
      startEditingOneTime(
        expense_id,
        onetime_id,
        name,
        cost,
        date,
        cat.name,
        jwt
      )
    );
    setEditOneTimeOpen(false);
  };

  const handleEditRecurringClick = (recurring) => {
    setSelectedRecurring(recurring);
    setEditRecurringOpen(true);
  };

  const handleEditRecurringClose = () => {
    setEditRecurringOpen(false);
    setSelectedRecurring({
      budget_id: -1,
      category_id: -1,
      category_name: "",
      cost: 0,
      recurring_id: 0,
      renewal_date: "",
    });
  };

  const handleEditRecurring = (
    expense_id,
    recurring_id,
    name,
    cost,
    renewal_date,
    user,
    category,
    cat,
    currentBudget
  ) => {
    dispatch(
      startEditingRecurring(
        expense_id,
        recurring_id,
        name,
        cost,
        renewal_date,
        cat.name,
        jwt
      )
    );
    setEditRecurringOpen(false);
  };

  const createOneTimeRows = () => {
    return oneTimeExpenses.map((oneTime) => (
      <ExpenseRow
        expense={oneTime}
        handleDelete={handleDeleteOneTimeClick}
        handleEdit={handleEditOneTimeClick}
      />
    ));
  };

  const createRecurringRows = () => {
    return recurringExpenses.map((recurring) => (
      <ExpenseRow
        expense={recurring}
        handleDelete={handleDeleteRecurringClick}
        handleEdit={handleEditRecurringClick}
      />
    ));
  };

  const handleBudgetChange = (event) => {
    event.preventDefault();
    dispatch(setCurrentBudget(budgets, parseInt(event.target.value)));
  };

  return (
    <div className="content container">
      <Notification></Notification>
      <div className="w-25">
        {currentBudget.budget_id !== -1 && (
          <Form>
            <Form.Label>Current Budget</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              defaultValue={currentBudget.budget_id}
              onChange={handleBudgetChange}
            >
              {budgets.map((budget) => {
                return (
                  <option key={budget.budget_id} value={budget.budget_id}>
                    {budget.month}, {budget.year}
                  </option>
                );
              })}
            </Form.Control>
          </Form>
        )}
      </div>

      <div className="d-flex justify-content-between align-items-center my-2">
        <h1>One-time Expenses</h1>

        <Button
          className="mb-3 float-right"
          variant="primary"
          onClick={handleAddOneTimeOpen}
        >
          Add One-Time Expense
        </Button>

        {categories.length !== 0 && (
          <OneTimeExpenseModal
            oneTime={{
              budget_id: -1,
              category_id: -1,
              category_name: "",
              cost: 0,
              onetime_id: 0,
              date: "",
            }}
            open={addOneTimeOpen}
            onClose={handleAddOneTimeClose}
            onSubmit={handleAddOneTime}
            categories={categories}
            title="Add One-Time Expense"
          />
        )}
      </div>

      <Table striped bordered hover variant={theme === "dark" ? "dark" : ""}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Category</th>
            <th>Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{oneTimeExpenses ? createOneTimeRows() : <></>}</tbody>
      </Table>

      {categories.length !== 0 && (
        <OneTimeExpenseModal
          oneTime={selectedOneTime}
          open={editOneTimeOpen}
          onClose={handleEditOneTimeClose}
          onSubmit={handleEditOneTime}
          categories={categories}
          title="Edit One-Time Expense"
          categoryDisabled={true}
        />
      )}

      <hr />

      <div className="d-flex justify-content-between align-items-center my-2">
        <h1>Recurring Expenses</h1>
        <Button
          className="mb-3 float-right"
          variant="primary"
          onClick={handleAddRecurringOpen}
        >
          Add Recurring Expense
        </Button>

        {categories.length !== 0 && (
          <RecurringExpenseModal
            recurring={{
              budget_id: -1,
              category_id: -1,
              category_name: "",
              cost: 0,
              recurring_id: 0,
              renewal_date: "",
            }}
            open={addRecurringOpen}
            onClose={handleAddRecurringClose}
            onSubmit={handleAddRecurring}
            categories={categories}
            title="Add Recurring Expense"
          />
        )}
      </div>
      <Table striped bordered hover variant={theme === "dark" ? "dark" : ""}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Renewal Date</th>
            <th>Category</th>
            <th>Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{recurringExpenses ? createRecurringRows() : <></>}</tbody>
      </Table>

      {/* Delete Modal for onetime expenses */}
      <DeleteModal
        open={deleteOneTimeOpen}
        title={"Delete OneTime Expense"}
        onClose={() => {
          setDeleteOneTimeOpen(false);
          setExpenseToDelete({});
        }}
        onDelete={handleConfirmDeleteOneTime}
        expense={expenseToDelete}
      />

      {/* Delete Modal for onetime expenses */}
      <DeleteModal
        open={deleteRecurringOpen}
        title={"Delete Recurring Expense"}
        onClose={() => {
          setDeleteRecurringOpen(false);
          setExpenseToDelete({});
        }}
        onDelete={handleConfirmDeleteRecurring}
        expense={expenseToDelete}
      />

      {categories.length !== 0 && (
        <RecurringExpenseModal
          recurring={selectedRecurring}
          open={editRecurringOpen}
          onClose={handleEditRecurringClose}
          onSubmit={handleEditRecurring}
          categories={categories}
          categoryDisabled={true}
          title="Edit Recurring Expense"
        />
      )}
    </div>
  );
}

export default ExpensesComponent;

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  startAddingBudget,
  setCurrentBudget,
  getCategories,
  startGettingRecurringExpenses,
} from "../actions";
import { adjustDate } from "../utils/adjustDate";
import { deepCopyArray } from "../utils/deepCopyArray";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

function AddBudget({ history }) {
  const dispatch = useDispatch();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [amount, setAmount] = useState("");
  const [importedRecurringExpenses, setImportedRecurringExpenses] = useState(
    []
  );
  const [importCategories, setImportCategories] = useState(false);
  const [importRecurringExpenses, setImportRecurringExpenses] = useState(false);

  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme);
  const authenticated = useSelector((state) => state.authenticated);
  const categories = useSelector((state) => state.categories);
  const budgets = useSelector((state) => state.budgets);
  const currentBudget = useSelector((state) => state.currentBudget);
  const recurringExpenses = useSelector((state) => state.recurringExpenses);
  const jwt = useSelector((state) => state.jwt);

  useEffect(() => {
    if (!authenticated) {
      history.push("/");
    }
  });

  useEffect(() => {
    let d = new Date();
    setMonth(d.toLocaleString("default", { month: "long" }));
    setYear(d.getFullYear());
  }, []);

  useEffect(() => {
    dispatch(getCategories(user.user_id, currentBudget.budget_id, jwt));
    dispatch(
      startGettingRecurringExpenses(user.user_id, currentBudget.budget_id, jwt)
    );
  }, [dispatch, user.user_id, jwt, currentBudget.budget_id]);

  useEffect(() => {
    let newRecurringExpenses = deepCopyArray(recurringExpenses);

    //Adjust the date in the recurring expenses
    for (let i = 0; i < newRecurringExpenses.length; i++) {
      newRecurringExpenses[i].renewal_date = adjustDate(
        newRecurringExpenses[i].renewal_date,
        month,
        year
      );
    }

    setImportedRecurringExpenses(newRecurringExpenses);
  }, [recurringExpenses, month, year]);

  const handleBudgetChange = (event) => {
    event.preventDefault();
    dispatch(setCurrentBudget(budgets, parseInt(event.target.value)));
  };

  const onSave = (e) => {
    e.preventDefault();
    if (importCategories) {
      if (importRecurringExpenses) {
        let re = groupRecurringExpenses(importedRecurringExpenses);
        dispatch(
          startAddingBudget(
            month,
            year,
            amount,
            categories,
            re,
            user.user_id,
            jwt,
            history
          )
        );
      } else {
        dispatch(
          startAddingBudget(
            month,
            year,
            amount,
            categories,
            false,
            user.user_id,
            jwt,
            history
          )
        );
      }
    } else {
      dispatch(
        startAddingBudget(
          month,
          year,
          amount,
          [],
          false,
          user.user_id,
          jwt,
          history
        )
      );
    }

    setImportCategories(false);
  };

  const groupRecurringExpenses = (newRecurringExpenses) => {
    let recurringExpensesCategories = [];
    let recurringExpensesGrouped = new Map();

    //Get all of the categories that the recurring expenses have
    for (const recurringExpense of newRecurringExpenses) {
      if (
        !recurringExpensesCategories.includes(recurringExpense.category_name)
      ) {
        recurringExpensesCategories.push(recurringExpense.category_name);
      }
    }

    //Group the recurring expenses by category
    for (const recurringExpenseCategory of recurringExpensesCategories) {
      let expenses = [];

      // get the expenses for a category
      for (const recurringExpense of newRecurringExpenses) {
        if (recurringExpenseCategory === recurringExpense.category_name) {
          expenses.push(recurringExpense);
        }
      }

      recurringExpensesGrouped.set(recurringExpenseCategory, expenses);
    }

    return recurringExpensesGrouped;
  };

  const handleMonthChange = (event) => {
    event.preventDefault();
    setMonth(event.target.value);
  };

  return (
    <div className="container">
      <h1>Add Budget</h1>
      <Form onSubmit={onSave}>
        <Form.Group controlId="budgetMonth">
          <Form.Label>Month</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            value={month}
            onChange={handleMonthChange}
          >
            <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="budgetYear">
          <Form.Label>Year</Form.Label>
          <Form.Control
            required
            type="number"
            value={year}
            onChange={(e) => setYear(parseInt(e.target.value))}
          />
        </Form.Group>
        <Form.Group controlId="budgetAmount">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            required
            type="number"
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value))}
          />
        </Form.Group>

        <hr />

        {currentBudget.budget_id !== -1 && (
          <Form.Group controlId="budgetCategories">
            <Form.Check
              type="checkbox"
              label="Import Categories"
              checked={importCategories}
              onChange={(e) => setImportCategories(!importCategories)}
            />
          </Form.Group>
        )}

        {importCategories && (
          <Form className="w-25 mr-3 mb-4">
            <Form.Label>Categories From</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              defaultValue={currentBudget.budget_id}
              onChange={handleBudgetChange}
            >
              {budgets.map((budget) => {
                return (
                  <option key={budget.budget_id} value={budget.budget_id}>
                    {budget.month}, {budget.year}
                  </option>
                );
              })}
            </Form.Control>
          </Form>
        )}

        {importCategories && categories.length !== 0 && (
          <Table
            striped
            bordered
            hover
            variant={theme === "dark" ? "dark" : ""}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category.category_id}>
                  <td>{category.name}</td>
                  <td>{category.amount_of_budget}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {importCategories && categories.length === 0 && (
          <p>Sorry, there are no categories to import</p>
        )}

        <Form.Group controlId="budgetRecurringExpenses">
          <Form.Check
            type="checkbox"
            label="Import Recurring Expenses"
            checked={importRecurringExpenses}
            disabled={!importCategories || categories.length === 0}
            onChange={(e) =>
              setImportRecurringExpenses(!importRecurringExpenses)
            }
          />
        </Form.Group>

        {importRecurringExpenses &&
          importCategories &&
          categories.length !== 0 &&
          recurringExpenses.length !== 0 && (
            <Table
              striped
              bordered
              hover
              variant={theme === "dark" ? "dark" : ""}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Renewal Date</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                {importedRecurringExpenses.map((recurringExpense) => (
                  <tr key={recurringExpense.recurring_id}>
                    <td>{recurringExpense.name}</td>
                    <td>{recurringExpense.category_name}</td>
                    <td>{recurringExpense.renewal_date.substring(0, 10)}</td>
                    <td>{recurringExpense.cost}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

        {importRecurringExpenses &&
          importCategories &&
          recurringExpenses.length === 0 && (
            <p>Sorry, there are no recurring expenses to import</p>
          )}

        <Button type="submit">Save</Button>
      </Form>
    </div>
  );
}

export default withRouter(AddBudget);

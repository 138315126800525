import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function RecurringExpenseModal(props) {
  const user = useSelector((state) => state.user);
  const currentBudget = useSelector((state) => state.currentBudget);

  const [open, setOpen] = useState(props.open);
  const [name, setName] = useState(props.recurring.name);
  const [renewalDate, setRenewalDate] = useState(
    props.recurring.renewal_date.substring(0, 10)
  );
  const [cost, setCost] = useState(props.recurring.cost);
  const [category, setCategory] = useState(props.categories[0].category_id);

  useEffect(() => {
    setOpen(props.open);
    setName(props.recurring.name);
    setRenewalDate(props.recurring.renewal_date.substring(0, 10));
    setCost(props.recurring.cost);
    setCategory(
      props.recurring.category_id === -1
        ? props.categories[0].category_id
        : props.recurring.category_id
    );
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let cat = props.categories.find(
      (c) => c.category_id === parseInt(category)
    );
    props.onSubmit(
      props.recurring.expense_id,
      props.recurring.recurring_id,
      name,
      cost,
      renewalDate,
      user,
      parseInt(category),
      cat,
      currentBudget
    );
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="recurring-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="onetime-modal">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Category</Form.Label>
            <Form.Control
              required
              as="select"
              value={category}
              onChange={(e) => setCategory(parseInt(e.target.value))}
              disabled={props.categoryDisabled}
            >
              {props.categories.map((category) => {
                return (
                  <option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Cost</Form.Label>
            <Form.Control
              required
              type="number"
              value={cost}
              onChange={(e) => setCost(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Renewal Date</Form.Label>
            <Form.Control
              required
              type="date"
              value={renewalDate}
              onChange={(e) => setRenewalDate(e.target.value)}
            />
          </Form.Group>
          <Button className="float-right" type="submit" variant="primary">
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default RecurringExpenseModal;

export function createExpenseAreaChartData(
  recurringExpenses,
  onetimeExpenses,
  month
) {
  const months31 = [
    "January",
    "March",
    "May",
    "July",
    "August",
    "October",
    "December",
  ];
  const months30 = ["April", "June", "September", "November"];
  let intervals = ["1st", "5th", "10th", "15th", "20th", "25th"];
  let endDate;

  if (months31.includes(month)) {
    intervals.push("31st");
    endDate = 31;
  } else if (months30.includes(month)) {
    intervals.push("30th");
    endDate = 30;
  } else if (month === "February") {
    intervals.push("28th");
    endDate = 28;
  }

  let recurringExpensesTotals = getRecurringExpenseIntervalTotals(
    recurringExpenses,
    endDate
  );

  let onetimeExpensesTotals = getOneTimeExpenseIntervalTotals(
    onetimeExpenses,
    endDate
  );

  let data = [];

  for (let i = 0; i < onetimeExpensesTotals.length; i++) {
    let obj = {
      name: intervals[i],
      onetime: onetimeExpensesTotals[i],
      recurring: recurringExpensesTotals[i],
      expenseTotal: onetimeExpensesTotals[i] + recurringExpensesTotals[i],
    };
    data.push(obj);
  }

  return data;
}

function getRecurringExpenseIntervalTotals(recurringExpenses, endDate) {
  const reducer = (accumulator, currentValue) =>
    accumulator + currentValue.cost;

  let firstIntervalTotal = recurringExpenses
    .filter(
      (recurringExpense) =>
        parseInt(recurringExpense.renewal_date.substring(8, 10)) === 1
    )
    .reduce(reducer, 0);

  let secondIntervalTotal = recurringExpenses
    .filter(
      (recurringExpense) =>
        parseInt(recurringExpense.renewal_date.substring(8, 10)) < 5
    )
    .reduce(reducer, 0);
  let thirdIntervalTotal = recurringExpenses
    .filter(
      (recurringExpense) =>
        parseInt(recurringExpense.renewal_date.substring(8, 10)) < 10
    )
    .reduce(reducer, 0);

  let fourthIntervalTotal = recurringExpenses
    .filter(
      (recurringExpense) =>
        parseInt(recurringExpense.renewal_date.substring(8, 10)) < 15
    )
    .reduce(reducer, 0);

  let fifthIntervalTotal = recurringExpenses
    .filter(
      (recurringExpense) =>
        parseInt(recurringExpense.renewal_date.substring(8, 10)) < 20
    )
    .reduce(reducer, 0);

  let sixthIntervalTotal = recurringExpenses
    .filter(
      (recurringExpense) =>
        parseInt(recurringExpense.renewal_date.substring(8, 10)) < 25
    )
    .reduce(reducer, 0);

  let seventhIntervalTotal = recurringExpenses
    .filter(
      (recurringExpense) =>
        parseInt(recurringExpense.renewal_date.substring(8, 10)) <= endDate
    )
    .reduce(reducer, 0);

  return [
    firstIntervalTotal,
    secondIntervalTotal,
    thirdIntervalTotal,
    fourthIntervalTotal,
    fifthIntervalTotal,
    sixthIntervalTotal,
    seventhIntervalTotal,
  ];
}

function getOneTimeExpenseIntervalTotals(oneTimeExpenses, endDate) {
  const reducer = (accumulator, currentValue) =>
    accumulator + currentValue.cost;

  let firstIntervalTotal = oneTimeExpenses
    .filter(
      (oneTimeExpense) => parseInt(oneTimeExpense.date.substring(8, 10)) === 1
    )
    .reduce(reducer, 0);

  let secondIntervalTotal = oneTimeExpenses
    .filter(
      (oneTimeExpense) => parseInt(oneTimeExpense.date.substring(8, 10)) < 5
    )
    .reduce(reducer, 0);
  let thirdIntervalTotal = oneTimeExpenses
    .filter(
      (oneTimeExpense) => parseInt(oneTimeExpense.date.substring(8, 10)) < 10
    )
    .reduce(reducer, 0);

  let fourthIntervalTotal = oneTimeExpenses
    .filter(
      (oneTimeExpense) => parseInt(oneTimeExpense.date.substring(8, 10)) < 15
    )
    .reduce(reducer, 0);

  let fifthIntervalTotal = oneTimeExpenses
    .filter(
      (oneTimeExpense) => parseInt(oneTimeExpense.date.substring(8, 10)) < 20
    )
    .reduce(reducer, 0);

  let sixthIntervalTotal = oneTimeExpenses
    .filter(
      (oneTimeExpense) => parseInt(oneTimeExpense.date.substring(8, 10)) < 25
    )
    .reduce(reducer, 0);

  let seventhIntervalTotal = oneTimeExpenses
    .filter(
      (oneTimeExpense) =>
        parseInt(oneTimeExpense.date.substring(8, 10)) <= endDate
    )
    .reduce(reducer, 0);

  return [
    firstIntervalTotal,
    secondIntervalTotal,
    thirdIntervalTotal,
    fourthIntervalTotal,
    fifthIntervalTotal,
    sixthIntervalTotal,
    seventhIntervalTotal,
  ];
}

export function adjustDate(renewalDate, budgetMonth, budgetYear) {
  const months30 = ["April", "June", "September", "November"];

  let newRenewalDate = "";
  let months = new Map([
    ["January", "01"],
    ["February", "02"],
    ["March", "03"],
    ["April", "04"],
    ["May", "05"],
    ["June", "06"],
    ["July", "07"],
    ["August", "08"],
    ["September", "09"],
    ["October", "10"],
    ["November", "11"],
    ["December", "12"],
  ]);

  let dateIntString = renewalDate.substring(8, 10);
  let date = parseInt(dateIntString);

  let newDate = "";
  let newMonth = "";

  //set the month
  newMonth = months.get(budgetMonth);

  if (date <= 28) {
    newDate = dateIntString;
  } else if (budgetMonth === "February" && date > 28) {
    newDate = "28";
    console.log("here");
  } else if (months30.includes(budgetMonth) && date > 30) {
    newDate = "30";
  } else {
    newDate = dateIntString;
  }

  newRenewalDate = budgetYear + "-" + newMonth + "-" + newDate;

  return newRenewalDate;
}

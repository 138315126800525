import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  getCategories,
  setCurrentBudget,
  startAddingCategory,
  startEditingCategory,
  startDeletingCategory,
  //DismissNotification,
  startDeletingBudget,
  startEditingBudget,
} from "../actions";
import { useSelector, useDispatch } from "react-redux";
import CategoryModal from "./CategoryModal";
import BudgetModal from "./BudgetModal";
import Notification from "./Notification";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

function BudgetComponent(props) {
  const categories = useSelector((state) => state.categories);
  const jwt = useSelector((state) => state.jwt);
  const theme = useSelector((state) => state.theme);
  const user = useSelector((state) => state.user);
  const budgets = useSelector((state) => state.budgets);
  const currentBudget = useSelector((state) => state.currentBudget);
  const dispatch = useDispatch();

  const [categoryChart, setCategoryChart] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState({
    category_id: -1,
    name: "",
    amount: 0,
  });

  const [selectedBudget, setSelectedBudget] = useState({
    budget_id: -1,
    month: "",
    year: 0,
    amount: 0,
  });

  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [editCategoryOpen, setEditCategoryOpen] = useState(false);

  const [editBudgetOpen, setEditBudgetOpen] = useState(false);

  useEffect(() => {
    dispatch(getCategories(user.user_id, currentBudget.budget_id, jwt));
  }, [dispatch, user.user_id, jwt, currentBudget.budget_id]);

  useEffect(() => {
    setCategoryChart(
      categories.map((category) => {
        return {
          name: category.name,
          amount: category.amount_of_budget,
        };
      })
    );
  }, [categories]);

  //create a time-out function that closes the alerts after 5 seconds

  /*useEffect(() => {
    const timer = setTimeout(function () {
      dispatch(DismissNotification());
    }, 5000);
    return () => clearTimeout(timer);
  }, [dispatch]);
*/

  const handleBudgetChange = (event) => {
    event.preventDefault();
    dispatch(setCurrentBudget(budgets, parseInt(event.target.value)));
  };

  const handleAddCategoryOpen = (event) => {
    event.preventDefault();
    setAddCategoryOpen(true);
  };

  const handleAddCategoryClose = () => {
    setAddCategoryOpen(false);
  };

  const handleAddCategory = (category_id, name, amount) => {
    dispatch(startAddingCategory(name, amount, currentBudget.budget_id, jwt));
    setAddCategoryOpen(false);
  };

  const handleEditCategoryClick = (category) => {
    setSelectedCategory(category);
    setEditCategoryOpen(true);
  };

  const handleEditCategoryClose = () => {
    setEditCategoryOpen(false);
    setSelectedCategory({
      category_id: -1,
      name: "",
      amount: 0,
    });
  };

  const handleEditCategory = (category_id, name, amount) => {
    dispatch(startEditingCategory(category_id, name, amount, jwt));
    setEditCategoryOpen(false);
  };

  const handleEditBudgetClick = (budget) => {
    setSelectedBudget(budget);
    setEditBudgetOpen(true);
  };

  const handleEditBudgetClose = () => {
    setEditBudgetOpen(false);
    setSelectedBudget({
      budget_id: -1,
      month: "",
      year: 0,
      amount: 0,
    });
  };

  const handleEditBudget = (budget_id, month, year, amount) => {
    dispatch(startEditingBudget(budget_id, month, year, amount, jwt));
    setEditBudgetOpen(false);
  };

  const handleDeleteCategory = (category_id) => {
    dispatch(startDeletingCategory(category_id, jwt));
  };

  const handleDeleteBudget = (budget_id) => {
    dispatch(startDeletingBudget(budget_id, jwt));
  };

  const pieChart_RADIANS = Math.PI / 180;

  const pieColors = [
    "#FFA3A5",
    "#8884D8",
    "#BA5624",
    "#FFC658",
    "#82CA9D",
    "#615756",
    "#EF476F",
    "#04724D",
  ];

  const onChangeColor = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * pieChart_RADIANS);
    const y = cy + radius * Math.sin(-midAngle * pieChart_RADIANS);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="auto"
      >
        {`${(percent * 100).toFixed(0)}% `}| ${categoryChart[index].amount}
      </text>
    );
  };

  return (
    <div className="content container">
      <Notification></Notification>
      <h1>Budget</h1>
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex flex-column">
          {currentBudget.budget_id !== -1 && (
            <Form>
              <Form.Label>Current Budget</Form.Label>
              <Form.Control
                as="select"
                size="sm"
                defaultValue={currentBudget.budget_id}
                onChange={handleBudgetChange}
              >
                {budgets.map((budget) => {
                  return (
                    <option key={budget.budget_id} value={budget.budget_id}>
                      {budget.month}, {budget.year}
                    </option>
                  );
                })}
              </Form.Control>
            </Form>
          )}
        </div>

        {currentBudget.budget_id !== -1 && (
          <div>
            <h4>Budget Amount:</h4>
            <h5>${currentBudget.amount}</h5>
          </div>
        )}

        <PieChart width={400} height={400}>
          <Pie
            dataKey="amount"
            isAnimationActive={false}
            data={categoryChart}
            outerRadius={160}
            fill="#DF0101"
            labelLine={false}
            label={onChangeColor}
          >
            {categoryChart.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={pieColors[index % pieColors.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>

      <BudgetModal
        budget={selectedBudget}
        open={editBudgetOpen}
        onClose={handleEditBudgetClose}
        onSubmit={handleEditBudget}
        title="Edit Budget"
      />

      <div className="d-flex justify-content-between align-items-center mb-2">
        <h1>Categories</h1>
        {currentBudget.budget_id !== -1 && (
          <Button
            className="mb-3 float-right"
            variant="primary"
            onClick={handleAddCategoryOpen}
          >
            Add Category
          </Button>
        )}
      </div>

      <CategoryModal
        category={{ category_id: -1, name: "", amount: 0 }}
        open={addCategoryOpen}
        onClose={handleAddCategoryClose}
        onSubmit={handleAddCategory}
        title="Add Category"
      />

      {categories.length === 0 ? (
        <p>Looks like you need to add some categories for your budget</p>
      ) : (
        <Table striped bordered hover variant={theme === "dark" ? "dark" : ""}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <Category
                key={category.category_id}
                category={category}
                handleEditCategory={handleEditCategoryClick}
                handleDeleteCategory={handleDeleteCategory}
              />
            ))}
          </tbody>
        </Table>
      )}

      <CategoryModal
        category={selectedCategory}
        open={editCategoryOpen}
        onClose={handleEditCategoryClose}
        onSubmit={handleEditCategory}
        title="Edit Category"
      />

      <div className="d-flex justify-content-between align-items-center mb-2">
        <h1>Previous Budgets</h1>
        <Button
          className="mt-2 float-right"
          onClick={(e) => {
            e.preventDefault();
            props.history.push("/budget/create");
          }}
        >
          Add Budget
        </Button>
      </div>

      {budgets.length === 0 ? (
        <p></p>
      ) : (
        <Table striped bordered hover variant={theme === "dark" ? "dark" : ""}>
          <thead>
            <tr>
              <th>Month</th>
              <th>Year</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {budgets.map((budget) => (
              <Budget
                key={budget.budget_id}
                budget={budget}
                handleEditBudget={handleEditBudgetClick}
                handleDeleteBudget={handleDeleteBudget}
              />
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

function Budget(props) {
  const budget = props.budget;

  const handleEditBudget = (e) => {
    e.preventDefault();
    props.handleEditBudget(budget);
  };

  const handleDeleteBudget = (e) => {
    e.preventDefault();
    props.handleDeleteBudget(budget.budget_id);
  };
  return (
    <tr>
      <td>{budget.month}</td>
      <td>{budget.year}</td>
      <td>${budget.amount}</td>
      <td>
        <FontAwesomeIcon
          icon={faEdit}
          className="icon"
          onClick={handleEditBudget}
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="icon"
          onClick={handleDeleteBudget}
        />
      </td>
    </tr>
  );
}

function Category(props) {
  const category = props.category;

  const handleEditCategory = (e) => {
    e.preventDefault();
    props.handleEditCategory(category);
  };

  const handleDeleteCategory = (e) => {
    e.preventDefault();
    props.handleDeleteCategory(category.category_id);
  };

  return (
    <tr>
      <td>{category.name}</td>
      <td>${category.amount_of_budget}</td>
      <td>
        <FontAwesomeIcon
          icon={faEdit}
          className="icon"
          onClick={handleEditCategory}
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="icon"
          onClick={handleDeleteCategory}
        />
      </td>
    </tr>
  );
}

export default BudgetComponent;

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function BudgetModal(props) {
  const [open, setOpen] = useState(props.open);
  const [month, setMonth] = useState(props.budget.month);
  const [year, setYear] = useState(props.budget.year);
  const [amount, setAmount] = useState(props.budget.amount);

  useEffect(() => {
    setOpen(props.open);
    setMonth(props.budget.month);
    setYear(props.budget.year);
    setAmount(props.budget.amount);
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit(props.budget.budget_id, month, year, amount);
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="budget-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="camera-modal">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Month</Form.Label>
            <Form.Control
              required
              type="text"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Year</Form.Label>
            <Form.Control
              required
              type="number"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              required
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
          <Button className="float-right" type="submit" variant="primary">
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default BudgetModal;

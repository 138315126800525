import { sortBudgets } from "./utils/sortBudgets";

export const Action = Object.freeze({
  /* User Signup and Login */
  FinishLoggingInUser: "FinishLoggingInUser",
  FinishAddingUser: "FinishAddingUser",
  FinishSettingUser: "FinishSettingUser",
  FinishEditingUser: "FinishEditingUser",
  FinishChangingTheme: "FinishChangingTheme",

  /* Budget */
  FinishSettingBudget: "FinishSettingBudget",
  FinishAddingBudget: "FinishAddingBudget",
  SetCurrentBudget: "SetCurrentBudget",
  FinishDeletingBudget: "FinishDeletingBudget",
  FinishEditingBudget: "FinishEditingBudget",

  /* Categories */
  FinishLoadingCategories: "FinishLoadingCategories",
  FinishAddingCategory: "FinishAddingCategory",
  FinishEditingCategory: "FinishEditingCategory",
  FinishDeletingCategory: "FinishDeletingCategory",
  addNotification: "addNotification",
  dismissNotification: "dismissNotification",

  /* Expenses */
  FinishLoadingOneTimeExpenses: "FinishLoadingOneTimeExpenses",
  FinishAddingOneTime: "FinishAddingOneTime",
  FinishEditingOneTime: "FinishEditingOneTime",
  FinishDeletingOneTime: "FinishDeletingOneTime",
  FinishLoadingRecurringExpenses: "FinishLoadingRecurringExpenses",
  FinishAddingRecurring: "FinishAddingRecurring",
  FinishEditingRecurring: "FinishEditingRecurring",
  FinishDeletingRecurring: "FinishDeletingRecurring",
});

export const host = "https://shrouded-mesa-70840.herokuapp.com";

function checkForErrors(response) {
  if (!response.ok) {
    throw Error(`${response.status}: ${response.statusText}`);
  }
  return response;
}

/*********************************** User Login ***********************************/

export function startLoggingInUser(email, password, history) {
  const user = { email, password };
  const loginOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(user),
  };

  return (dispatch) => {
    fetch(`${host}/user/login`, loginOptions)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(finishLoggingInUser(data.jwt));
          history.push("/dashboard");

          const getUserOptions = {
            method: "GET",
            headers: {
              Authorization: `Bearer ${data.jwt}`,
              "Content-Type": "application/json",
            },
            credentials: "include",
          };
          fetch(`${host}/user`, getUserOptions)
            .then(checkForErrors)
            .then((response) => response.json())
            .then((userData) => {
              if (userData.ok) {
                dispatch(finishSettingUser(userData.user[0]));
                dispatch(
                  startSettingBudget(userData.user[0].user_id, data.jwt)
                );
                dispatch(DismissNotification());
              }
            })
            .catch((e) => {
              console.error(e);
              dispatch(
                AddNotification({
                  type: "danger",
                  message: "Error getting user",
                })
              );
            });
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Invalid username or password",
          })
        );
      });
  };
}

export function finishLoggingInUser(jwt) {
  return {
    type: Action.FinishLoggingInUser,
    payload: jwt,
  };
}

export function finishSettingUser(user) {
  return {
    type: Action.FinishSettingUser,
    payload: user,
  };
}

export function startEditingUser(first_name, last_name, user_id, jwt) {
  const user = { first_name, last_name };
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  };

  return (dispatch) => {
    fetch(`${host}/user/edit`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          user.user_id = user_id;
          dispatch(FinishEditingUser(user));
          dispatch(
            AddNotification({
              type: "success",
              message: "User information successfully updated!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "User information failed to be edited, so sorry. :(",
          })
        );
      });
  };
}

export function FinishEditingUser(user) {
  return {
    type: Action.FinishEditingUser,
    payload: user,
  };
}

/*********************************** Change color mode ***********************************/

export function startChangingTheme(theme, jwt) {
  const user_theme = { theme };
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user_theme),
  };

  return (dispatch) => {
    fetch(`${host}/user/darkTheme`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishChangingTheme());
          dispatch(
            AddNotification({
              type: "success",
              message: "Theme successfully updated!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Theme failed to be updated",
          })
        );
      });
  };
}

export function FinishChangingTheme() {
  return {
    type: Action.FinishChangingTheme,
    payload: {},
  };
}

/*********************************** User Logout ***********************************/

export function startLoggingOutUser(history) {
  return (dispatch) => {
    document.cookie = "jwt= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    dispatch(finishLoggingOutUser());
    history.push("/");
  };
}

export function finishLoggingOutUser() {
  return {
    type: Action.FinishLoggingInUser,
    payload: "",
  };
}

/*********************************** User Signup ***********************************/

export function startAddingUser(
  first_name,
  last_name,
  email,
  password,
  history
) {
  const user = { first_name, last_name, email, password };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  };

  return (dispatch) => {
    fetch(`${host}/user/signup`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          user.user_id = data.id;
          dispatch(finishAddingUser(user));
          history.push("/");
          dispatch(
            AddNotification({
              type: "success",
              message: "User Created Successfully!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Warning! This email is already in use!",
          })
        );
      });
  };
}

export function finishAddingUser(user) {
  return {
    type: Action.FinishAddingUser,
    payload: user,
  };
}

/*********************************** Budget ***********************************/

export function startSettingBudget(user_id, jwt) {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    credentials: "include",
  };
  return (dispatch) => {
    fetch(`${host}/budget/${user_id}/allbudgets`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          data.budgets = sortBudgets(data.budgets);
          dispatch(finishSettingBudget(data.budgets));
          dispatch(getCategories(user_id, data.budgets[0].budget_id, jwt));
        }
      })
      .catch((e) => {
        console.error(e);
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Error setting budget",
          })
        );
      });
  };
}

export function finishSettingBudget(budgets) {
  if (budgets.length !== 0) {
    return {
      type: Action.FinishSettingBudget,
      payload: { currentBudget: budgets[0], budgets: budgets },
    };
  } else {
    return {
      type: Action.FinishSettingBudget,
      payload: { currentBudget: { budget_id: -1 }, budgets: [] },
    };
  }
}

export function setCurrentBudget(budgets, budget_id) {
  let newCurrentBudget = budgets.find((budget) => {
    return budget.budget_id === budget_id;
  });

  return {
    type: Action.SetCurrentBudget,
    payload: newCurrentBudget,
  };
}
/*********************************** Categories ***********************************/
/* Get */
export function getCategories(user_id, budget_id, jwt) {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    credentials: "include",
  };
  return (dispatch) => {
    fetch(`${host}/category/${user_id}/${budget_id}/all`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishLoadingCategories(data.categories));
        }
      })
      .catch((e) => console.error(e));
  };
}

export function FinishLoadingCategories(categories) {
  return {
    type: Action.FinishLoadingCategories,
    payload: categories,
  };
}

/* Post */
export function startAddingCategory(name, amount_of_budget, budget_id, jwt) {
  const category = { name, amount_of_budget, budget_id };
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(category),
  };

  return (dispatch) => {
    fetch(`${host}/category/create`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          category.category_id = data.id;
          dispatch(FinishAddingCategory(category));
          dispatch(
            AddNotification({
              type: "success",
              message: "Category Successfully created!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Category failed to be created",
          })
        );
      });
  };
}

export function startAddingCategoryWithExpenses(
  name,
  amount_of_budget,
  budget_id,
  expenses,
  user_id,
  jwt
) {
  const category = { name, amount_of_budget, budget_id };
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(category),
  };

  return (dispatch) => {
    fetch(`${host}/category/create`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          category.category_id = data.id;
          dispatch(FinishAddingCategory(category));
          for (const expense of expenses) {
            dispatch(
              startAddingRecurring(
                expense.name,
                expense.cost,
                expense.renewal_date,
                user_id,
                category.category_id,
                name,
                budget_id,
                jwt
              )
            );
          }
          dispatch(
            AddNotification({
              type: "success",
              message: "Category Successfully created!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Category failed to be created",
          })
        );
      });
  };
}

export function FinishAddingCategory(category) {
  return {
    type: Action.FinishAddingCategory,
    payload: category,
  };
}

/* Patch */
export function startEditingCategory(category_id, name, amount_of_budget, jwt) {
  const category = { category_id, name, amount_of_budget };
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(category),
  };

  return (dispatch) => {
    fetch(`${host}/category/edit`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishEditingCategory(category));
          dispatch(
            AddNotification({
              type: "success",
              message: "Category Successfully edited!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Category failed to be edited",
          })
        );
      });
  };
}

export function FinishEditingCategory(category) {
  return {
    type: Action.FinishEditingCategory,
    payload: category,
  };
}

/* Delete */

export function startDeletingCategory(category_id, jwt) {
  const category = { category_id };
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(category),
  };

  return (dispatch) => {
    fetch(`${host}/category/delete`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishDeletingCategory(category));
          dispatch(
            AddNotification({
              type: "success",
              message: "Category Deleted Successfully!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Category failed to be deleted",
          })
        );
      });
  };
}

/*********************************** Adding Budget ***********************************/

export function startAddingBudget(
  month,
  year,
  amount,
  categories,
  recurringExpenses,
  user_id,
  jwt,
  history
) {
  const budget = { month, year, amount };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(budget),
  };

  return (dispatch) => {
    fetch(`${host}/budget/create`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          budget.budget_id = data.id;
          dispatch(finishAddingBudget(budget));
          if (categories.length !== 0) {
            for (const category of categories) {
              if (recurringExpenses) {
                let expenses = recurringExpenses.get(category.name);
                dispatch(
                  startAddingCategoryWithExpenses(
                    category.name,
                    category.amount_of_budget,
                    budget.budget_id,
                    expenses,
                    user_id,
                    jwt
                  )
                );
              } else {
                dispatch(
                  startAddingCategory(
                    category.name,
                    category.amount_of_budget,
                    budget.budget_id,
                    jwt
                  )
                );
              }
            }
          }
          dispatch(
            AddNotification({
              type: "success",
              message: "Monthly budget added successfully!",
            })
          );
          history.push("/budget");
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "failed to add",
          })
        );
      });
  };
}

/*********************************** Edit Budget ***********************************/

export function startEditingBudget(budget_id, month, year, amount, jwt) {
  const budget = { budget_id, month, year, amount };
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(budget),
  };

  return (dispatch) => {
    fetch(`${host}/budget/edit`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishEditingBudget(budget));
          dispatch(
            AddNotification({
              type: "success",
              message: "Budget Successfully edited!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Budget failed to be edited",
          })
        );
      });
  };
}

export function FinishEditingBudget(budget) {
  return {
    type: Action.FinishEditingBudget,
    payload: budget,
  };
}

export function finishAddingBudget(budget) {
  return {
    type: Action.FinishAddingBudget,
    payload: budget,
  };
}

export function FinishDeletingCategory(category) {
  return {
    type: Action.FinishDeletingCategory,
    payload: category,
  };
}

export function AddNotification(notification) {
  return {
    type: Action.addNotification,
    payload: notification,
  };
}

export function DismissNotification() {
  return {
    type: Action.dismissNotification,
    payload: {},
  };
}

/*********************************** Deleting Budget ***********************************/

export function startDeletingBudget(budget_id, jwt) {
  const budget = { budget_id };
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(budget),
  };

  return (dispatch) => {
    fetch(`${host}/budget/delete`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishDeletingBudget(budget));
          dispatch(
            AddNotification({
              type: "success",
              message: "Budget Deleted Successfully!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Budget failed to be deleted",
          })
        );
      });
  };
}

export function FinishDeletingBudget(budget) {
  return {
    type: Action.FinishDeletingBudget,
    payload: budget,
  };
}

/*********************************** One-Time Expenses ***********************************/

export function startGettingOneTimeExpenses(user_id, budget_id, jwt) {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    credentials: "include",
  };
  return (dispatch) => {
    fetch(`${host}/onetime/${user_id}/${budget_id}`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishLoadingOneTimeExpenses(data.oneTimeExpenses));
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Error getting one-time expenses",
          })
        );
      });
  };
}

export function FinishLoadingOneTimeExpenses(oneTimeExpenses) {
  return {
    type: Action.FinishLoadingOneTimeExpenses,
    payload: oneTimeExpenses,
  };
}

export function startAddingOneTime(
  name,
  cost,
  date,
  user_id,
  category_id,
  category_name,
  budget_id,
  jwt
) {
  const oneTimeExpense = {
    name,
    cost,
    date,
    user_id,
    category_id,
    budget_id,
    category_name,
  };
  const expense = { name, cost, user_id, category_id };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(oneTimeExpense),
  };

  return (dispatch) => {
    fetch(`${host}/onetime/create`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          expense.expense_id = data.expense_id;
          oneTimeExpense.expense_id = data.expense_id;
          oneTimeExpense.onetime_id = data.onetime_id;
          dispatch(FinishAddingOneTime(expense, oneTimeExpense));
          dispatch(
            AddNotification({
              type: "success",
              message: "One-Time expense added successfully!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Failed to add one-time expense",
          })
        );
      });
  };
}

export function FinishAddingOneTime(expense, oneTimeExpense) {
  return {
    type: Action.FinishAddingOneTime,
    payload: { expense, oneTimeExpense },
  };
}

export function startEditingOneTime(
  expense_id,
  onetime_id,
  name,
  cost,
  date,
  category_name,
  jwt
) {
  const oneTimeExpense = {
    onetime_id,
    expense_id,
    name,
    cost,
    date,
    category_name,
  };
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(oneTimeExpense),
  };

  return (dispatch) => {
    fetch(`${host}/onetime/edit`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishEditingOneTime(oneTimeExpense));
          dispatch(
            AddNotification({
              type: "success",
              message: "One Time Expense Successfully edited!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "One Time Expense failed to be edited",
          })
        );
      });
  };
}

export function FinishEditingOneTime(oneTimeExpense) {
  return {
    type: Action.FinishEditingOneTime,
    payload: oneTimeExpense,
  };
}

export function startDeletingOneTimeExpense(expense_id, onetime_id, jwt) {
  const expense = { expense_id, onetime_id };
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(expense),
  };

  return (dispatch) => {
    fetch(`${host}/onetime/delete`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishDeletingOneTime(expense));
          dispatch(
            AddNotification({
              type: "success",
              message: "Expense Deleted Successfully!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Expense failed to be deleted",
          })
        );
      });
  };
}

export function FinishDeletingOneTime(expense) {
  return {
    type: Action.FinishDeletingOneTime,
    payload: expense,
  };
}

/*********************************** Recurring Expenses ***********************************/
export function startGettingRecurringExpenses(user_id, budget_id, jwt) {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    credentials: "include",
  };
  return (dispatch) => {
    fetch(`${host}/recurring/${user_id}/${budget_id}`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishLoadingRecurringExpenses(data.recurringExpenses));
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Error getting recurring expenses",
          })
        );
      });
  };
}

export function FinishLoadingRecurringExpenses(recurringExpenses) {
  return {
    type: Action.FinishLoadingRecurringExpenses,
    payload: recurringExpenses,
  };
}

export function startAddingRecurring(
  name,
  cost,
  renewal_date,
  user_id,
  category_id,
  category_name,
  budget_id,
  jwt
) {
  const recurringExpense = {
    name,
    cost,
    renewal_date,
    user_id,
    category_id,
    category_name,
  };
  const expense = { name, cost, user_id, category_id };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(recurringExpense),
  };

  return (dispatch) => {
    fetch(`${host}/recurring/create`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          expense.expense_id = data.expense_id;
          recurringExpense.expense_id = data.expense_id;
          recurringExpense.recurring_id = data.recurring_id;
          dispatch(FinishAddingRecurring(expense, recurringExpense));
          dispatch(
            AddNotification({
              type: "success",
              message: "Recurring expense added successfully!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Failed to add recurring expense",
          })
        );
      });
  };
}

export function FinishAddingRecurring(expense, recurringExpense) {
  return {
    type: Action.FinishAddingRecurring,
    payload: { expense, recurringExpense },
  };
}

export function startEditingRecurring(
  expense_id,
  recurring_id,
  name,
  cost,
  renewal_date,
  category_name,
  jwt
) {
  const recurringExpense = {
    recurring_id,
    expense_id,
    name,
    cost,
    renewal_date,
    category_name,
  };
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(recurringExpense),
  };

  return (dispatch) => {
    fetch(`${host}/recurring/edit`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishEditingRecurring(recurringExpense));
          dispatch(
            AddNotification({
              type: "success",
              message: "Recurring Expense Successfully edited!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Recurring Expense failed to be edited",
          })
        );
      });
  };
}

export function FinishEditingRecurring(recurringExpense) {
  return {
    type: Action.FinishEditingRecurring,
    payload: recurringExpense,
  };
}

export function startDeletingRecurringExpense(expense_id, recurring_id, jwt) {
  const expense = { expense_id, recurring_id };
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(expense),
  };

  return (dispatch) => {
    fetch(`${host}/recurring/delete`, options)
      .then(checkForErrors)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(FinishDeletingRecurring(expense));
          dispatch(
            AddNotification({
              type: "success",
              message: "Expense Deleted Successfully!",
            })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AddNotification({
            type: "danger",
            message: "Expense failed to be deleted",
          })
        );
      });
  };
}

export function FinishDeletingRecurring(expense) {
  return {
    type: Action.FinishDeletingRecurring,
    payload: expense,
  };
}

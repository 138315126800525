import React, { useEffect } from "react";
import SideBar from "../components/SideBar";
import BudgetComponent from "../components/BudgetComponent";

import { useSelector } from "react-redux";

import { withRouter } from "react-router-dom";

function Budget({ history }) {
  const authenticated = useSelector((state) => state.authenticated);

  useEffect(() => {
    if (!authenticated) {
      history.push("/");
    }
  });

  return (
    <>
      <SideBar history={history} active="budget" />
      <BudgetComponent history={history} />
    </>
  );
}

export default withRouter(Budget);

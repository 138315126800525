import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function DeleteModal(props) {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleDelete = () => {
    setOpen(false);
    props.onDelete(props.expense);
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      size="md"
      aria-labelledby="delete-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="delete-modal">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure that you would like to perform this delete operation? It
          cannot be undone.
        </p>
        <Button
          className=""
          type="submit"
          variant="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="float-right"
          type="submit"
          variant="danger"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteModal;

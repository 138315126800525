import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../styles/dashboard.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {
  getCategories,
  startGettingOneTimeExpenses,
  startGettingRecurringExpenses,
  setCurrentBudget,
} from "../actions";
import { createExpenseAreaChartData } from "../utils/graphs";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  PieChart,
  Pie,
  Cell,
  Area,
  AreaChart,
} from "recharts";

function DashboardComponent(props) {
  const budgets = useSelector((state) => state.budgets);
  const currentBudget = useSelector((state) => state.currentBudget);
  const recurringExpenses = useSelector((state) => state.recurringExpenses);
  const oneTimeExpenses = useSelector((state) => state.oneTimeExpenses);
  const jwt = useSelector((state) => state.jwt);
  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme);
  const categories = useSelector((state) => state.categories);
  const dispatch = useDispatch();

  const [expenseChart, setExpenseChart] = useState([]);
  const [expenseTotalsChart, setExpenseTotalsChart] = useState([]);
  const [budgetsGraph, setBudgetsGraph] = useState([]);
  const [categoryChart, setCategoryChart] = useState([]);
  const [oneTimeChart, setOneTimeChart] = useState([]);

  useEffect(() => {
    dispatch(getCategories(user.user_id, currentBudget.budget_id, jwt));
    dispatch(
      startGettingOneTimeExpenses(user.user_id, currentBudget.budget_id, jwt)
    );
    dispatch(
      startGettingRecurringExpenses(user.user_id, currentBudget.budget_id, jwt)
    );
  }, [dispatch, user.user_id, jwt, currentBudget.budget_id]);

  useEffect(() => {
    setExpenseChart(
      recurringExpenses.map((recurring) => {
        return {
          date: recurring.renewal_date.substring(0, 10),
          cost: recurring.cost,
        };
      })
    );
  }, [recurringExpenses]);

  useEffect(() => {
    setExpenseTotalsChart(
      createExpenseAreaChartData(
        recurringExpenses,
        oneTimeExpenses,
        currentBudget.month
      )
    );
  }, [recurringExpenses, oneTimeExpenses, currentBudget.month]);

  useEffect(() => {
    setCategoryChart(
      categories.map((category) => {
        return {
          name: category.name,
          amount: category.amount_of_budget,
        };
      })
    );
  }, [categories]);

  useEffect(() => {
    setBudgetsGraph(
      budgets
        .slice(0, 6)
        .reverse()
        .map((budget) => {
          return {
            name: budget.month.substring(0, 3).toUpperCase(),
            amount: budget.amount,
          };
        })
    );
  }, [budgets]);

  useEffect(() => {
    setOneTimeChart(
      oneTimeExpenses.map((oneTime) => {
        return {
          date: oneTime.date.substring(0, 10),
          oneTimeCost: oneTime.cost,
        };
      })
    );
  }, [oneTimeExpenses]);

  const handleBudgetChange = (event) => {
    event.preventDefault();
    dispatch(setCurrentBudget(budgets, parseInt(event.target.value)));
  };

  const pieChart_RADIANS = Math.PI / 180;

  const pieColors = [
    "#FFA3A5",
    "#8884D8",
    "#BA5624",
    "#FFC658",
    "#82CA9D",
    "#615756",
    "#EF476F",
    "#04724D",
  ];

  const onChangeColor = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * pieChart_RADIANS);
    const y = cy + radius * Math.sin(-midAngle * pieChart_RADIANS);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="auto"
      >
        {`${(percent * 100).toFixed(0)}% `}| ${categoryChart[index].amount}
      </text>
    );
  };

  return (
    <div className="content container">
      <div className="d-flex justify-content-between align-items-center my-2">
        <h3>Budget</h3>

        {currentBudget.budget_id !== -1 && (
          <Form className="w-25 mr-3">
            <Form.Label>Current Budget</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              defaultValue={currentBudget.budget_id}
              onChange={handleBudgetChange}
            >
              {budgets.map((budget) => {
                return (
                  <option key={budget.budget_id} value={budget.budget_id}>
                    {budget.month}, {budget.year}
                  </option>
                );
              })}
            </Form.Control>
          </Form>
        )}
      </div>
      <div className="graphs">
        <Row>
          <Col md={6}>
            {categories.length === 0 && (
              <h4>Not enough data to display a graph</h4>
            )}
            {categories.length !== 0 && (
              <div className="d-flex justify-content-center">
                <PieChart width={300} height={300}>
                  <Pie
                    dataKey="amount"
                    isAnimationActive={false}
                    data={categoryChart}
                    outerRadius={120}
                    fill="#DF0101"
                    labelLine={false}
                    label={onChangeColor}
                  >
                    {categoryChart.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={pieColors[index % pieColors.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </div>
            )}
          </Col>
          <Col md={6}>
            {budgets.length < 2 && <h4>Not enough data to display a graph</h4>}
            {budgets.length >= 2 && (
              <div className="d-flex justify-content-center">
                <LineChart
                  width={500}
                  height={300}
                  data={budgetsGraph}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="amount"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </div>
            )}
          </Col>
        </Row>
        <h3>Expenses</h3>
        <Row>
          <Col md={6}>
            <div className="recurringChart">
              <LineChart
                width={450}
                height={300}
                data={expenseChart}
                margin={{
                  top: 35,
                  right: 35,
                  left: 35,
                  bottom: 35,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date">
                  <Label
                    angle={0}
                    value="Date"
                    position="insideBottom"
                    style={{
                      textAnchor: "middle",
                      fill: ` ${theme === "dark" ? "#666" : "black"}`,
                    }}
                    fontSize="25px"
                    dy={25}
                  />
                  <Label
                    angle={0}
                    value="RecurringExpense"
                    position="insideBottom"
                    style={{
                      textAnchor: "middle",
                      fill: ` ${theme === "dark" ? "#666" : "black"}`,
                    }}
                    fontSize="25px"
                    dy={-335}
                  />
                </XAxis>
                <YAxis>
                  <Label
                    angle={-90}
                    value="Cost"
                    position="insideLeft"
                    style={{
                      textAnchor: "middle",
                      fill: ` ${theme === "dark" ? "#666" : "black"}`,
                    }}
                    fontSize="25px"
                    dx={-10}
                  />
                </YAxis>

                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="cost" stroke="red"></Line>
              </LineChart>
              <LineChart
                width={450}
                height={300}
                data={oneTimeChart}
                margin={{
                  top: 35,
                  right: 35,
                  left: 35,
                  bottom: 35,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date">
                  <Label
                    angle={0}
                    value="Date"
                    position="insideBottom"
                    style={{
                      textAnchor: "middle",
                      fill: ` ${theme === "dark" ? "#666" : "black"}`,
                    }}
                    fontSize="25px"
                    dy={25}
                  />
                  <Label
                    angle={0}
                    value="OneTimeExpense"
                    position="insideBottom"
                    style={{
                      textAnchor: "middle",
                      fill: ` ${theme === "dark" ? "#666" : "black"}`,
                    }}
                    fontSize="25px"
                    dy={-335}
                  />
                </XAxis>
                <YAxis>
                  <Label
                    angle={-90}
                    value="Cost"
                    position="insideLeft"
                    style={{
                      textAnchor: "middle",
                      fill: ` ${theme === "dark" ? "#666" : "black"}`,
                    }}
                    fontSize="25px"
                    dx={-10}
                  />
                </YAxis>
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="oneTimeCost"
                  stroke="green"
                ></Line>
              </LineChart>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-center">
              <AreaChart
                width={500}
                height={300}
                data={expenseTotalsChart}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="onetime"
                  stackId="1"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
                <Area
                  type="monotone"
                  dataKey="recurring"
                  stackId="1"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                />
                <Area
                  type="monotone"
                  dataKey="expenseTotal"
                  stackId="1"
                  stroke="#ffc658"
                  fill="#ffc658"
                />
              </AreaChart>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default DashboardComponent;

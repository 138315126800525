export function sortBudgets(budgets) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const sorter = (a, b) => {
    if (a.year !== b.year) {
      return b.year - a.year;
    } else {
      return months.indexOf(b.month) - months.indexOf(a.month);
    }
  };

  budgets.sort(sorter);

  return budgets;
}

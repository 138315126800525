import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { startEditingUser, startChangingTheme } from "../actions";
import Notification from "./Notification";
import "../styles/App.css";

function AccountComponent() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const jwt = useSelector((state) => state.jwt);

  const onEdit = (event) => {
    event.preventDefault();
    dispatch(startEditingUser(firstName, lastName, user.user_id, jwt));
  };

  const handleDarkMode = (event) => {
    event.preventDefault();
    let newTheme = user.dark_theme === 0 ? 1 : 0;
    dispatch(startChangingTheme(newTheme, jwt));
  };

  return (
    <div className="content container">
      <Notification></Notification>
      <h1>Account Settings</h1>
      <h3>Account Info</h3>
      <Form onSubmit={onEdit}>
        <Form.Group controlId="editFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="editLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>
        <Button type="submit">Save Edits</Button>
      </Form>
      <hr />
      <h3>Color Mode</h3>
      <Button
        variant={theme === "light" ? "dark" : "light"}
        onClick={handleDarkMode}
      >
        {theme === "light" ? "Dark Mode" : "Light Mode"}
      </Button>
    </div>
  );
}

export default AccountComponent;
